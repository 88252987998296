angular.module('enervexSalesappApp').controller('ConfirmCtrl', function($scope, $uibModalInstance, title, body) {
	$scope.title = title;
	$scope.body = body;

	$scope.confirm = function() {
		$scope.applModalOpened = false;
		var modalInstance = $uibModalInstance.close(true);
	}
	$scope.dismiss = function() {
		var modalInstance = $uibModalInstance.dismiss(false);

	}
});
